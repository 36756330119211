import React from 'react'
import SEO from 'components/elements/SEO/SEO'
import CustomImage from 'components/foundations/Image/CustomImage'
import PhoneIcon from '@material-ui/icons/Phone'
import RoomIcon from '@material-ui/icons/Room'
import MailIcon from '@material-ui/icons/Mail'
import DomainIcon from '@material-ui/icons/Domain'

export default function AboutPage() {
  return (
    <>
      <SEO title="Contatti" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <div className="about closer">
        <CustomImage filename="contacts.jpg" />
        <div className="contacts-content">
          <h3>Le Nostre Sedi</h3>
          <div className="contacts-grid">
            <div className="contacts-item">
              <div className="maps">
                <iframe
                  title="ING GROUP Napoli"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.044585442989!2d14.12128361565793!3d40.82698433850935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133b0e6ad3409dd1%3A0x125e6deab0efaae9!2sVia%20Milite%20Ignoto%2C%2020%2C%2080078%20Pozzuoli%20NA!5e0!3m2!1sit!2sit!4v1615906080401!5m2!1sit!2sit"
                  width="350"
                  height="260"
                  style={{ border: '5px solid #f7f7f7' }}
                  allowFullScreen=""
                  loading="lazy"
                />
              </div>
              <strong>Sede di NAPOLI</strong>
              <div className="contacts-items-info">
                <p>
                  <RoomIcon className="icon-style" />
                  Via Milite Ignoto, 20 80078 Pozzuoli (NA)
                </p>
                <p>
                  <PhoneIcon className="icon-style" />
                  Tel : &nbsp;081.19369982/3
                </p>
                <p>
                  <DomainIcon className="icon-style" />
                  P.IVA : &nbsp;06836631215
                </p>
                <p>
                  <MailIcon className="icon-style" />
                  Email : &nbsp;info@ing-group.it
                </p>
              </div>
            </div>
            <div className="contacts-item">
              <div className="maps">
                <iframe
                  title="ING GROUP Milano"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.0550914158493!2d9.135025815766435!3d45.44854594250862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3cc0554ff3d%3A0x1315e81ddaa91ce7!2sLargo%20dei%20Gelsomini%2C%206%2C%2020146%20Milano%20MI!5e0!3m2!1sit!2sit!4v1615906956836!5m2!1sit!2sit"
                  width="350"
                  height="260"
                  style={{ border: '5px solid #f7f7f7' }}
                  allowFullScreen=""
                  loading="lazy"
                />
              </div>
              <strong>Sede di MILANO</strong>
              <div className="contacts-items-info">
                <p>
                  <RoomIcon className="icon-style" />
                  Largo dei Gelsomini, 6 – 20146 MILANO (MI)
                </p>
                <p>
                  <PhoneIcon className="icon-style" />
                  Tel : &nbsp;0298650575
                </p>
                <p>
                  <DomainIcon className="icon-style" />
                  P.IVA : &nbsp;06836631215
                </p>
                <p>
                  <MailIcon className="icon-style" />
                  Email : &nbsp;info@ing-group.it
                </p>
              </div>
            </div>
            <div className="contacts-item">
              <div className="maps">
                <iframe
                  title="ING GROUP Vicenza"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.4043513349116!2d11.60926811576826!3d45.52206803760076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f2e15edc37da7%3A0xa42188e958428671!2sVia%20Roma%2C%20100%2C%2036040%20Torri%20di%20Quartesolo%20VI!5e0!3m2!1sit!2sit!4v1615907152827!5m2!1sit!2sit"
                  width="350"
                  height="260"
                  style={{ border: '5px solid #f7f7f7' }}
                  allowFullScreen=""
                  loading="lazy"
                />
              </div>
              <strong>Sede di Vicenza</strong>
              <div className="contacts-items-info">
                <p>
                  <RoomIcon className="icon-style" />
                  Via Roma 100 – 36040 Torri Di Quartesolo (VI)
                </p>
                <p>
                  <PhoneIcon className="icon-style" />
                  Tel : &nbsp;04441500119
                </p>
                <p>
                  <DomainIcon className="icon-style" />
                  P.IVA : &nbsp;06836631215
                </p>
                <p>
                  <MailIcon className="icon-style" />
                  Email : &nbsp;info@ing-group.it
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
